@import "../../../../sass/index.scss";

.button {
	padding: 11px 12px;
	display: flex;
	width: 100%;
	align-items: center;
	border-radius: 8px;
	cursor: pointer;
	font-size: 13px;
	justify-content: center;
}

.buttonFill {
	background: var(--primary-default);
	color: var(--background-primary);
	cursor: pointer;
	margin-top: 30px;
}

.buttonBorder {
	border: 1px solid var(--border-primary);

	@include buttonTransition();

	&:hover {
		border: 1px solid var(--border-hover);
	}
}
