.openApp {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	top: 0;
	left: 0;
	width: 100%;
	background: var(--background-secondary);
	padding: 10px;
	z-index: 9999;

	.mainBtn {
		padding: 6px 13px;
		background: var(--text-secondary);
		color: black;
		border-radius: 5px;
		font-size: 0.8em;
		position: relative;
		z-index: 999;
	}

	button {
		margin-left: 15px;
		display: flex;
		align-items: center;
	}
}
