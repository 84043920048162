.inputBox {
	margin-bottom: 12px;
	position: relative;

	input {
		display: flex;
		width: 100%;
		height: 40px;
		padding: 11px 30px 11px 30px;
		align-items: center;
		flex-shrink: 0;
		border-radius: 8px;
		border: 1px solid var(--border-secondary);
		background: var(--background-tertiary);
		color: var(--text-primary);

		transition: border 0.3s;

		&::placeholder {
			color: var(--text-tertiary);
		}

		&:hover {
			border: 1px solid var(--border-hover);
		}
	}

	svg {
		position: absolute;
		top: 20px;
		left: 10px;
		transform: translateY(-50%);

		width: 16px;
		height: 16px;

		path {
			stroke-width: 1px;
		}
	}
}

.underlinedInput {
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid var(--border-secondary, #c8d3e0);
	width: 100%;

	gap: 8px;
	align-self: stretch;

	& label {
		color: var(--text-secondary, #5c6168);
		width: fit-content;
		white-space: nowrap;
	}

	& input {
		padding-left: 0;
		border: none;
		outline: none;
		background: transparent;
	}
}

.underlinedInput {
	display: flex;
	gap: 10px;
	justify-content: flex-end;
}

.btnEye {
	position: absolute;
	right: 0;
	top: 0;
	padding: 20px;

	svg {
		stroke: var(--icon-primary);
		width: 20px;
		height: 20px;
	}
}
