.login {
	display: flex;
	background: var(--background-primary);

	&_image {
		width: 55%;
		overflow-y: hidden;
		height: 100vh;

		& img {
			height: 100vh;
			width: 100%;
		}
	}

	&_textBox {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		width: 310px;
		overflow-y: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	&_text {
		width: 100%;
	}

	&_join {
		color: var(--text-link);
		margin-bottom: 5px;
		font-size: 22px;
		font-weight: 600;
	}

	&_doMore {
		color: var(--text-secondary);
		margin-bottom: 40px;
		font-size: 14px;
	}

	&_continueRingplan {
		display: flex;
		width: 100%;
		padding: 11px 12px;
		align-items: center;
		border-radius: 8px;
		border: 1px solid var(--border-primary);
		margin-bottom: 25px;

		& img {
			width: 25px;
		}

		span {
			display: inline-block;
			width: 100%;
			text-align: center;
		}
	}

	&_inputBox {
		margin-bottom: 12px;
		position: relative;

		&_error {
			color: var(--text-danger, #ee3939);
			height: 17px;
			padding: 0 10px;
		}

		& input {
			display: flex;
			width: 100%;
			height: 40px;
			padding: 11px 30px 11px 30px;
			align-items: center;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid var(--border-secondary);
			background: var(--background-tertiary);

			&::placeholder {
				color: var(--text-tertiary);
			}
		}

		& svg {
			position: absolute;
			top: 35%;
			left: 10px;
			transform: translateY(-50%);
		}
	}

	&_signIn {
		display: flex;
		width: 100%;
		padding: 11px 12px;
		justify-content: center;
		align-items: center;

		border-radius: 8px;
		background: var(--primary-default);
		color: var(--text-on-color);
		cursor: pointer;
		margin-top: 30px;
	}
}

.or {
	text-align: center;
	margin-top: 15px;
	margin-bottom: 10px;
	color: var(--text-secondary);
}

.continueRingplan {
	img {
		width: 25px;
	}

	span {
		display: inline-block;
		width: 100%;
		text-align: center;
		color: var(--text-primary);
	}
}

.loginWithExtension {
	&_toggle {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		width: 100%;
		color: var(--text-secondary);
		margin-bottom: 20px;
		font-size: 13px;

		transition: color 0.3s;

		&:hover {
			color: var(--text-primary);
		}
	}

	&_chevronDown,
	&_chevronUp {
		background: var(--background-quaternary);
		height: 22px;
		width: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 0 !important;
		border-radius: 100%;
		svg {
			width: 13px;
		}
	}

	.btn {
		button {
			width: 100%;
		}
	}
}

@media screen and (max-width: 1200px) {
	.login {
		min-height: 100vh;

		&_image {
			display: none;
		}
	}
}

@media only screen and (max-width: 320px) {
	.login_textBox {
		width: 100%;
		padding: 0 20px;
	}
}
