@import "./../abstracts/variables";

@font-face {
	font-family: poppins_bold;
	src: url("./../../assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
	font-family: poppins_semibold;
	src: url("./../../assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
	font-family: poppins_medium;
	src: url("./../../assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
	font-family: poppins_regular;
	src: url("./../../assets/fonts/Poppins/Poppins-Regular.ttf");
}

* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box !important;
	line-height: unset !important;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	font-display: swap;
	font-family: "Poppins";
	max-width: 100vw;
}

ul,
li {
	list-style: none;
	list-style-type: none;
}

img {
	vertical-align: middle;
}

button {
	cursor: pointer;
	background: none;
	outline: none;
	border: none;
}

a,
p,
span,
li,
div,
button,
input,
textarea {
	font-family: poppins_regular !important;
}

input {
	border: none;
	background: none;
	color: var(--text-primary);
}

textarea {
	color: var(--text-primary);
}

/* Hide the number input arrow buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	/* Optional: Define styles for appearance and cursor */
	-moz-appearance: textfield; /* Firefox */
	appearance: textfield; /* Other browsers */
	cursor: text;
}

textarea {
	background: none;
}

a {
	text-decoration: none !important;
}

p {
	margin: 0;
	padding: 0;
}

.Wrapper {
	margin: auto;
}

.pagePopUp {
	position: relative;
	width: 100%;
	height: 100vh;
	z-index: 500;
}

.overlay {
	width: 100vw;
	height: 100vh;
	flex-shrink: 0;

	background: var(--overlay-primary, rgba(36, 40, 46, 0.1));

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;

	overflow: hidden;
}
