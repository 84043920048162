@import "../../sass/index.scss";

.splashScreen {
	background: var(--primary-default);
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.riVoiceLogo img {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: fadeOut 1.5s ease-out;
}

@keyframes fadeOut {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.ringplanLogo img {
	position: absolute;
	top: 90%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: slideInFromBottom 1.5s ease-out;
}

@keyframes slideInFromBottom {
	0% {
		transform: translate(-50%, 100%);
	}
	100% {
		transform: translate(-50%, -50%);
	}
}
