@mixin customScrollbar() {
	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-thumb {
		height: 4rem;
		border-radius: 0.5rem;
		border: 4px solid transparent;
		background-clip: content-box;
		background-color: var(--scroll-primary);
	}
}

@mixin buttonTransition() {
	transition:
		background-color 0.3s ease,
		border-color 0.3s ease,
		color 0.3s ease;
}

@mixin LargeTitleRegular() {
	/* Large Title/Regular */
	font-family: Poppins;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 40px; /* 125% */
	letter-spacing: -0.5px;
}

@mixin Title1Regular() {
	/* Title 1/Regular */
	font-family: Poppins;
	font-size: 28px;
	font-style: normal;
	font-weight: 500;
	line-height: 34px; /* 121.429% */
	letter-spacing: -0.5px;
}

@mixin Title2Regular() {
	/* Title 2/Regular */
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 127.273% */
	letter-spacing: -0.5px;
}

@mixin Title3Regular() {
	/* Title 3/Regular */
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 120% */
	letter-spacing: -0.5px;
}

@mixin HeadlineRegular() {
	/* Headline/Regular */
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px; /* 122.222% */
	letter-spacing: -0.5px;
}

@mixin SubheadlineRegular() {
	/* Subheadline/Regular */
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 125% */
	letter-spacing: -0.5px;
}

@mixin BodyRegular() {
	/* Body/Regular */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.5px;
}

@mixin FootnoteRegular() {
	/* Footnote/Regular */
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 138.462% */
	letter-spacing: -0.5px;
}

@mixin Caption1Regular() {
	/* Caption 1/Regular */
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 138.462% */
	letter-spacing: -0.2px;
}

@mixin Caption2Regular() {
	/* Caption 2/Regular */
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px; /* 127.273% */
	letter-spacing: -0.2px;
}

@mixin LargeTitleBold() {
	/* Large Title/Bold */
	font-family: Poppins;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 40px; /* 125% */
	letter-spacing: -0.5px;
}

@mixin Title1Bold() {
	/* Title 1/Bold */
	font-family: Poppins;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 34px; /* 121.429% */
	letter-spacing: -0.5px;
}

@mixin Title2Bold() {
	/* Title 2/Bold */
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 127.273% */
	letter-spacing: -0.5px;
}

@mixin Title3Bold() {
	/* Title 3/Bold */
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 120% */
	letter-spacing: -0.5px;
}

@mixin HeadlineBold() {
	/* Headline/Bold */
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px; /* 122.222% */
	letter-spacing: -0.5px;
}

@mixin SubheadlineBold() {
	/* Subheadline/Bold */
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 125% */
	letter-spacing: -0.5px;
}

@mixin BodyBold() {
	/* Body/Bold */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.5px;
}

@mixin FootnoteBold() {
	/* Footnote/Bold */
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 138.462% */
	letter-spacing: -0.5px;
}

@mixin Caption1Bold() {
	/* Caption 1/Bold */
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 138.462% */
	letter-spacing: -0.2px;
}

@mixin Caption2Bold() {
	/* Caption 2/Bold */
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px; /* 127.273% */
	letter-spacing: -0.2px;
}
