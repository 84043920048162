@keyframes slideFromRight {
	from {
		transform: translateX(100%);
	}
	to {
		/* Move to the final position when animation completes */
		transform: translateX(0);
	}
}

@keyframes slideIn {
	from {
		transform: translate(-50%, -200%);
		opacity: 0;
	}
	to {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}
