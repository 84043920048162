.loader {
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	background: var(--background-primary);
}

.loader_wrap {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	// background-color: red;
}

.loader_main {
	position: relative;
	width: 100px;
	height: 100px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ellipse1 {
	position: absolute;
	animation: rotate 4s linear infinite;
}

.ellipse2 {
	position: absolute;
	animation: rotate 4s linear infinite; /* 4s is the duration, adjust as needed */
}

.ellipse3 {
	position: absolute;
	animation: rotate2 4s linear infinite; /* 4s is the duration, adjust as needed */
}

@keyframes rotate {
	0% {
		transform: rotate(0deg) scale(1.2);
	}
	50% {
		transform: rotate(0deg) scale(1);
	}
	100% {
		transform: rotate(360deg) scale(1.2); /* Rotates the image in a full circle */
	}
}

@keyframes rotate2 {
	0% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0deg); /* Rotates the image in a full circle */
	}
}

.ri {
	position: absolute;
}
